<template>
  <div class="live-box" ref="livebox">
    <div style="" class="video-container flex-layout">
      <div class="video-wrapper">
        <!-- 勿删除 -->
        <!-- <video controls id="video" :src="videoSrc" controlsList="nodownload" autoplay muted>您的浏览器不支持 video 标签。</video> -->
        <!-- 勿删除 -->
        <!-- <video v-if="videoType" controls id="my-video" style="width:100%;height:100%" class="video-js"> -->
        <!-- <video v-if="videoType" controls id="my-video" style="width:100%;height:100%" class="video-js vjs-default-skin"
          preload="auto" controlsList="nodownload" autoplay muted>
          <source :src="videoSrc" type="application/x-mpegURL" />
        </video>
        <video v-else controls id="video" :src="videoSrc" controlsList="nodownload" autoplay muted></video> -->
        <div class="xgplayer" id="mse"></div>
      </div>
      <div class="video-sidebar flex-column">
        <div class="group time">
          <h3>课节时间</h3>
          <p v-if="!courseInfo.lessonsPattern">
            {{`${dayjs(lessonInfo.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss')} - ${ dayjs(lessonInfo.lessonsEndTime*1000).format('HH:mm:ss')}`}}
          </p>
          <p v-else>{{dayjs(lessonInfo.lessonsBeginTime*1000).format('YYYY-MM-DD')}}</p>
        </div>
        <div class="group introduction">
          <h3>课程介绍</h3>
          <p v-html="(courseInfo && courseInfo.courseExplain) || '暂无简介'"></p>
        </div>
        <!-- 提示弹窗 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" center>
          <span>你已开启多个视频，平台不支持多个视频同时播放</span>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 答题功能 暂时停用 勿删 -->
        <!-- <div class="group problem" v-if="showQuestion">
          <h3>答题</h3>
          <p class="stalk">1.学习科学（Learning Science）是由多个学科构成的交叉学科。下列选项与该交叉学科无直接关系的是</p>
          <div class="options">
            <el-radio :disabled="isRadioDis" v-model="radio" label="A">A.脑神经科学</el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="B">B.认知心理学</el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="C">C.教育技术学 </el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="D">D.图书情报学</el-radio>
          </div>
          <el-button type="primary" @click="submit()" :plain="true" :disabled="isBtnDis">{{btnWord}}</el-button>
          <div class="group answer" v-if="isAnswer">
            <p>正确答案：D 回答{{answerIsTrue}}</p>
            <p>答案解析：省略</p>
            <p>考察能力：学情分析能力</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  // import videojs from 'video.js'
  // import 'videojs-contrib-hls'
  import Player from 'xgplayer';
  import HlsJsPlayer from 'xgplayer-hls.js';
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        lessonInfo: {}, //课节信息
        courseInfo: {}, //课节所在在课程信息
        interval: undefined, //非m3u8视频计时定时器
        videoType: false,
        videoSrc: null,
        videoPlayer: null,
        playStamp: null, //本次播放标识
        lastStamp: null, //本次播放的进度
        totalStamp: null, //可拖拽的最大进度
        currentTime: null, //当前播放时间
        dialogVisible: false,
        isReplay: false, // 是否重播
        // 答题功能 暂停使用 勿删 ===========
        // radio: '', //按钮绑定value
        // showQuestion: false, //显隐问题
        // isRadioDis: false, //禁用选项
        // btnWord: '提交', //按钮文字
        // isBtnDis: false, //禁用按钮
        // isPause: true, //视频是否暂停
        // isAnswer: false,
        // answerIsTrue: '正确',
        // 答题功能 暂停使用 勿删 ===========
      };
    },
    computed: {},
    methods: {
      // 获取视频地址
      async getDownloadsUrl() {
        let resData = await this.$Api.Course.getLessonVideoUrl(this.$route.query.id);
        let requestUrl = `${this.getDownloadUrl}${resData.data}&type=5`
        axios({
          url: requestUrl,
          method: "get",
        }).then((res) => {
          console.log('获取视频地址成功', res)
          this.videoType = res.data.data.ext === 'm3u8';
          this.videoSrc = res.data.data.url;
          setTimeout(() => {
            if (this.videoType) {
              this.initPlayerBym3u8()
            } else {
              this.initPlayerBymp4()
            }
          }, 0)

        }).catch(function (error) {
          console.log(error);
        });
      },
      // 获取课节信息
      async getLessonInfo() {
        let resData = await this.$Api.Course.getAsnycLive(this.$route.query.id);
        this.lessonInfo = resData.data;
        this.$route.meta.title = this.lessonInfo.name;
        this.$store.commit('breadPageNameChange', this.lessonInfo.name)
        this.getCourseInfo(this.lessonInfo.courseProjectId)
      },
      //获取课程信息
      async getCourseInfo(courseId) {
        let resData = await this.$Api.Course.getCourseInfo(courseId)
        // console.log(resData);
        this.courseInfo = resData.data
      },
      // 观看计时
      async putCountTime() {
        this.currentTime = this.videoPlayer.currentTime
        let params = {
          lessonId: this.$route.query.id,
          learnTarget: this.playStamp, //播放标识
          progress: this.currentTime, //视频播放时长
        }
        let resData = await this.$Api.Course.countTime(params);
        console.log('视频计时', resData)
        try {
          if (resData.data.code == 1000) {
            // 暂停视频、清除定时器
            this.videoPlayer.pause();
            this.dialogVisible = true;
          }
        } catch (err) {
          console.log('code为200不处理')
        }
      },
      // 获取本次播放标识
      async getWatchStamp() {
        let params = {
          lessonId: this.$route.query.id,
          platform: 5, // 标识播放平台 5-研修平台
        }
        let resData = await this.$Api.Course.watchStamp(params);
        console.log('获取本次播放标识', resData);
        this.playStamp = resData.data.data.learnTarget;
        this.lastStamp = resData.data.data.lastProgress;
        this.totalStamp = resData.data.data.totalProgress;
        // 设置记忆时间
        this.videoPlayer.currentTime = resData.data.data.lastProgress;
        // this.videoPlayer.currentTime = resData.data.data.totalProgress;
      },
      //点击提交 答题功能 暂时停用  ====勿删====
      // submit() {
      //   if (this.radio === '') {
      //     this.$message.error('请选择一个答案');
      //     return
      //   } else {
      //     this.isRadioDis = true;
      //     this.$message({
      //       message: '提交成功',
      //       type: 'success'
      //     });
      //     this.btnWord = '已提交';
      //     this.isBtnDis = true;
      //     this.isAnswer = true;
      //     if (this.radio === 'D') {
      //       this.answerIsTrue = '正确'
      //     } else {
      //       this.answerIsTrue = '错误'
      //     }

      //     this.isPause = false;
      //     document.getElementById('video').play();
      //   }
      // },


      // 初始化m3u8播放器
      initPlayerBym3u8() {
        this.videoPlayer = new HlsJsPlayer({
          width: '100%',
          height: '100%',
          el: document.querySelector('#mse'),
          url: this.videoSrc,
          error: "视频异常",
          volume: 0.6, //初始音量
          autoplay: true, //自动播放
          cors: true, //请求是否跨域
          lang: 'zh-cn',
        });
        // 注册播放事件
        this.videoPlayer.on("play", () => {
          if (this.isReplay) {
            // 重播,不设置记忆时间但依旧计时
            this.videoPlayer.currentTime = 0;
            this.interval = setInterval(() => {
              this.putCountTime()
            }, 60 * 1000)
          } else {
            this.getWatchStamp();
            this.interval = setInterval(() => {
              this.putCountTime()
            }, 60 * 1000)
          }
        })
        // 注册暂停事件
        this.videoPlayer.on("pause", () => {
          console.log('暂停---', this.videoPlayer.currentTime)
          // this.currentTime = this.videoPlayer.currentTime;
          clearInterval(this.interval);
          this.putCountTime()
        })
        // 注册重播事件
        this.videoPlayer.on("replayBtnClick", () => {
          this.isReplay = true
        })
        // 注册seeking事件
        this.videoPlayer.on("seeking", () => {
          if (this.videoPlayer.currentTime > this.totalStamp) {
            console.log('拖拽时间不能大于已播过的最大时间');
            this.videoPlayer.currentTime = this.totalStamp
          }
        })

      },
      // 初始化mp4播放器
      initPlayerBymp4() {
        this.videoPlayer = new Player({
          width: '100%',
          height: '100%',
          id: 'mse',
          url: this.videoSrc,
          error: "视频异常",
          volume: 0.6, //初始音量
          autoplay: true, //自动播放
          cors: true, //请求是否跨域
          lang: 'zh-cn',
        });
        // 注册播放事件
        this.videoPlayer.on("play", () => {
          if (this.isReplay) {
            // 重播,不设置记忆时间但依旧计时
            this.videoPlayer.currentTime = 0;
            this.interval = setInterval(() => {
              this.putCountTime()
            }, 60 * 1000)
          } else {
            this.getWatchStamp();
            this.interval = setInterval(() => {
              this.putCountTime()
            }, 60 * 1000)
          }
        })
        // 注册暂停事件
        this.videoPlayer.on("pause", () => {
          console.log('暂停---', this.videoPlayer.currentTime)
          // this.currentTime = this.videoPlayer.currentTime;
          clearInterval(this.interval);
          this.putCountTime()
        })
        // 注册重播事件
        this.videoPlayer.on("replayBtnClick", () => {
          this.isReplay = true
        })
        // 注册seeking事件
        this.videoPlayer.on("seeking", () => {
          if (this.videoPlayer.currentTime > this.totalStamp) {
            console.log('拖拽时间不能大于已播过的最大时间');
            this.videoPlayer.currentTime = this.totalStamp
          }
        })
      }
    },
    // 销毁
    beforeDestroy() {
      if (this.player) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
        this.player.dispose();
      }
    },
    activated() {
      //勿删除
      // this.init();
      // this.getVideoInfo();
      // window.addEventListener('beforeunload', e => {
      //     this.putCountTime()
      // })
      //勿删除

    },
    mounted() {
      this.getLessonInfo();
      this.getDownloadsUrl();
    },
    destroyed() {
      clearInterval(this.interval);
    }
  };
</script>

<style lang="less" scoped>
  .live-box {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // padding: 0;
  }

  .bread-crumb {
    margin: 0px auto;
    width: 1200px;
  }

  .video-container {
    height: calc(100vh - 246px);
  }

  .video-wrapper {
    width: 90%;

    video {
      width: 100%;
      height: 100%;
      // max-width: 1300px;
      background-color: #000;
    }
  }

  .title {
    text-align: center;
    height: 2%;
  }

  .video-sidebar {
    padding: 0 20px;
    width: 20%;
    background-color: #282933;
    flex-shrink: 0;
    height: 100%;

    .group {
      margin-top: 20px;

      &:first-child {
        margin-top: 15px;
      }
    }


    h3 {
      line-height: 36px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;

      &::before {
        content: ' ';
        display: inline-block;
        border-radius: 25%;
        width: 4px;
        height: 20px;
        background-color: #218AB8;
        margin-right: 10px;
        margin-bottom: -5px;
      }
    }

    p {
      color: #fff;
      padding: 0 15px;
    }

    .introduction {
      flex: 1;

      &>p {
        overflow-y: auto;
        max-height: calc(100vh - 450px);

        /*修改滚动条样式*/
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        &::-webkit-scrollbar-button {
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #bfbfbf;
          border-radius: 4px;
        }
      }
    }

    .list /deep/.el-button--primary {
      margin: 20px 15px 0;
      color: #218AB8;
      background-color: transparent;
      border-color: #218AB8;
      padding: 12px 18px;
    }


    .time p {
      font-size: 14px;
      margin: 5px 0 20px;
    }

    .problem {
      .options {
        margin-bottom: 25px;

        /deep/.el-radio {
          display: block;
          margin: 10px 25px;
          color: #fff;
        }
      }

      /deep/.el-button--primary {
        width: 70%;
        display: block;
        margin: 0 auto;
      }

      /deep/.el-button--primary.is-plain {
        color: #fff;
        border-color: #409EFF;
        background-color: #409EFF;

        &:hover {
          background-color: #66b1ff;
          border-color: #66b1ff;
        }
      }
    }

  }


  iframe {
    margin: 0;
    padding: 0;
  }
</style>